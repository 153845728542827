<template>
  <v-list-item to="/app/home" color="black">
    <v-list-item-title>CURSOS</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "CoursesToolbarMenuItem",
};
</script>
