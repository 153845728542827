<template>
  <v-app-bar
    app
    :clipped-left="hasDrawer"
    :hide-on-scroll="hasDrawer"
    height="70px"
  >
    <v-btn text v-if="hasDrawer" class="hidden-md-and-up" @click="showDrawer">
      índice
    </v-btn>

    <v-spacer />

    <v-toolbar-title style="height: inherit">
      <router-link to="/">
        <v-avatar class="hidden-sm-and-up" height="100%">
          <v-img src="./logo-small.png" :contain="true" />
        </v-avatar>
        <v-img
          class="hidden-xs-only"
          src="./logo-horizontal.png"
          :contain="true"
          height="100%"
        />
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items pa-0>
      <v-btn
        text
        to="/app/home"
        px-5
        v-show="userIsLogged"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <span class="hidden-sm-and-down"> Cursos </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="hidden-md-and-up" v-on="on">mdi-school</v-icon>
          </template>
          <span>Cursos</span>
        </v-tooltip>
      </v-btn>

      <v-btn
        text
        px-5
        @click="$support.show()"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <span class="hidden-sm-and-down"> Soporte </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="hidden-md-and-up" v-on="on">
              mdi-help-circle
            </v-icon>
          </template>
          <span>Ayuda</span>
        </v-tooltip>
      </v-btn>

      <div v-show="userIsLogged">
        <v-container fill-height>
          <v-row align="center" justify="center">
            <toolbar-menu />
          </v-row>
        </v-container>
      </div>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { authGetters } from "@/store/auth";
import ToolbarMenu from "@/components/global/toolbar/ToolbarMenu";

export default {
  components: { ToolbarMenu },
  data() {
    return {
      hasDrawer: false,
    };
  },
  methods: {
    setDrawerButton() {
      this.hasDrawer = !!this.$route.meta.hasDrawer;
    },
    showDrawer() {
      this.$store.commit("showDrawer", true);
    },
  },
  created() {
    this.setDrawerButton();
  },
  watch: {
    $route() {
      this.setDrawerButton();
    },
  },
  computed: {
    userIsLogged() {
      return this.$store.getters[authGetters.userIsLogged];
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../styles/custom-colors"

.v-btn
  color: var(--v-black-base) !important
  font-family: "Josefin Sans", sans-serif
  font-weight: bold

  &:hover
    color: var(--v-blue-base) !important

  &.v-btn--active
    background-color: var(--v-brown-base)
</style>
