<template>
  <v-list-item @click="$support.show()" color="black">
    <v-list-item-title>AYUDA</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "HelpToolbarMenuItem",
};
</script>
