<template>
  <v-list-item @click="signOut">
    <v-list-item-title> CERRAR SESIÓN</v-list-item-title>
  </v-list-item>
</template>

<script>
import { authActions } from "@/store/auth";

export default {
  name: "SignoutToolbarMenuItem",
  methods: {
    async signOut() {
      const clearSession = this.$store.dispatch(authActions.logout);
      const clearUserData = this.$store.dispatch("clear");
      await clearSession;
      await clearUserData;

      //Removing query params from url before refresh
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ ...this.$router.currentRoute, query: {} });
      }

      this.$router.go(0);
    },
  },
};
</script>
