<template>
  <v-list-item to="/app/user/profile" color="black">
    <v-list-item-title>PERFIL</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "ProfileToolbarMenuItem",
};
</script>
