<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <courses-toolbar-menu-item v-if="$vuetify.breakpoint.xsOnly" />
      <help-toolbar-menu-item v-if="$vuetify.breakpoint.xsOnly" />
      <profile-toolbar-menu-item />
      <install-toolbar-menu-item />
      <signout-toolbar-menu-item />
    </v-list>
  </v-menu>
</template>

<script>
import InstallToolbarMenuItem from "./InstallToolbarMenuItem";
import SignoutToolbarMenuItem from "./SignoutToolbarMenuItem";
import ProfileToolbarMenuItem from "./ProfileToolbarMenuItem";
import CoursesToolbarMenuItem from "@/components/global/toolbar/CoursesToolbarMenuItem";
import HelpToolbarMenuItem from "@/components/global/toolbar/HelpToolbarMenuItem";

export default {
  name: "ToolbarMenu",
  components: {
    HelpToolbarMenuItem,
    CoursesToolbarMenuItem,
    ProfileToolbarMenuItem,
    SignoutToolbarMenuItem,
    InstallToolbarMenuItem,
  },
};
</script>

<style lang="sass">
.v-list-item__title
  font-family: "Josefin Sans", sans-serif
</style>
