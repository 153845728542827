<template>
  <v-list-item @click.stop="download">
    <v-list-item-title>
      INSTALAR EN DISPOSITIVO

      <v-dialog v-model="showIosInstallMessage" max-width="600px">
        <v-card>
          <v-card-title class="headline">Instalar en IOS</v-card-title>
          <v-card-text>
            <div class="my-5">
              <h4 class="mb-3">1- Pulsa en el icono de compartir</h4>
              <v-img src="./share-button.png" />
            </div>
            <div class="my-5">
              <h4 class="mb-3">
                2- Pulsa en el botón de "Añadir a la pantalla de inicio" ("Add
                to Home Screen")
              </h4>
              <v-img src="./add-to-home.png" />
            </div>
            <div class="my-5">
              <h4 class="mb-3">3- Pulsa en el botón de Añadir (Add)</h4>
              <v-img src="./accept-add-to-home.png" />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "InstallToolbarMenuItem",
  data() {
    return {
      showIosInstallMessage: false,
    };
  },
  computed: {
    installPrompt() {
      return this.$store.getters.getInstallPrompt;
    },
  },
  methods: {
    download() {
      if (this.isIos()) {
        this.showIosInstallMessage = true;
      } else if (this.installPrompt) {
        this.installPrompt.prompt();
        this.installPrompt.userChoice.then(function (result) {
          if (result.outcome === "accepted") {
            this.$snackbar.success("Se ha instalado correctamente");
          }
        });
      }
    },
    isIos() {
      const userAgent = window.navigator.userAgent;
      const isIPhone = userAgent.indexOf("iPhone") !== -1;
      const isIPad =
        userAgent.indexOf("iPad") !== -1 ||
        (navigator.platform === "MacIntel" &&
          typeof navigator.standalone !== "undefined");
      return isIPhone || isIPad;
    },
    isInstalled() {
      //TODO use this to hide this item if app is already installed
      const isIOsApp = window.navigator.standalone;
      const isChromeApp = window.matchMedia("(display-mode: standalone)")
        .matches;
      return isIOsApp || isChromeApp;
    },
  },
};
</script>
