<template>
  <div>
    <toolbar />
    <router-view />
  </div>
</template>

<script>
import Toolbar from "@/components/global/toolbar/Toolbar";

export default {
  components: { Toolbar },
};
</script>
